/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
// import { keys } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useElementSize from '../../hooks/useElementSize';
import { useDocumentSubmission, useMutateSubmission } from '../../hooks/useSubmissions';

import ContactField from '../../components/fields/ContactField';
import TextField from '../../components/fields/TextField';
import UserField from '../../components/fields/UserField';
// import SubscriptionForm from '../../components/forms/SubscriptionForm';
import Button from './Button';
import CircleButton from './CircleButton';
import Gauge from './Gauge';
import Kid, { headShapes, skinColors, looks } from './Kid';
import KidAudio from './KidAudio';
import KidPoints from './KidPoints';
import KidState from './KidState';
import Toolbar from './Toolbar';
// import musicSrc from './audio/mollogotchi.mp3';
import audioSpriteMp3 from './audio/sprite.mp3';
import * as icons from './img/icons';
import useAudio from './useAudio';
import useGotchiState from './useGotchiState';

import styles from './mollogotchi.module.css';

import audioSprite from './audio/sprite.json';
import logo from './img/logo.svg';

const propTypes = {
    id: PropTypes.string.isRequired,
    current: PropTypes.bool,
    createButtons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
        }),
    ),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
        }),
    ),
    current: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    current: false,
    createButtons: [
        {
            id: 'switch_head',
            key: 'head',
            choices: headShapes,
            icon: icons.head,
        },
        {
            id: 'switch_color',
            key: 'skinColor',
            choices: skinColors,
            icon: icons.skin,
        },
        {
            id: 'switch_look',
            key: 'look',
            choices: looks,
            icon: icons.clothes,
        },
    ],
    buttons: [
        { id: 'cuddle', index: 0, icon: icons.cuddle, priority: () => 0.5 },
        {
            id: 'cheese',
            index: 0,
            icon: icons.cheese,
            priority: ({ insatisfaction = 0 }) => (insatisfaction > 0.8 ? 0 : 1),
        },
        { id: 'toy', index: 1, icon: icons.toy, priority: () => 0.4 },
        {
            id: 'suce',
            index: 1,
            icon: icons.suce,
            priority: ({ isTired }) => (isTired ? 0 : 0.5),
        },
        {
            id: 'music',
            index: 2,
            icon: icons.music,
            priority: () => 0.5,
        },
        {
            id: 'milk',
            icon: icons.milk,
            index: 2,
            priority: ({ hungry = 0 }) => (hungry > 0.5 ? 0.1 : 1),
        },
        {
            id: 'diaper',
            icon: icons.diaper,
            index: 2,
            priority: ({ hasShit }) => (hasShit ? 0 : 1),
        },
    ],
    current: false,
    className: null,
};

function MolloGotchi({ id: documentId, createButtons, buttons, current, className }) {
    const { submission = null, isLoading = true } = useDocumentSubmission(documentId);
    const { kid: savedKid = null, points: savedPoints = null } = submission || {};
    const { submit } = useMutateSubmission();
    // console.log({
    //     submission,
    //     documentId,
    //     savedKid,
    //     savedPoints,
    // });
    const [kid, setKid] = useState(savedKid || null);
    const user = useUser();
    // console.log('user', user);
    const { phone: userPhone = null, email: userEmail = null } = user || {};
    const [loginType, setLoginType] = useState(userPhone !== null ? 'tel' : 'email');
    const [loginValue, setLoginValue] = useState(userPhone !== null ? userPhone : userEmail);
    const onLoginValueChange = useCallback((newValue) => setLoginValue(newValue), []);
    const onLoginTypeChange = useCallback((newType) => setLoginType(newType), []);
    const { ref: screenRef, width: screenWidth = 0, height: screenHeight = 0 } = useElementSize();
    const [currentStep, setCurrentStep] = useState(savedKid !== null ? 'play' : 'start');

    const music = useAudio(audioSpriteMp3, {
        volume: 0.2,
        sprite: audioSprite.sprite,
    });
    const audio = useAudio(audioSpriteMp3, {
        volume: 0.5,
        sprite: audioSprite.sprite,
    });

    const { name = null } = kid || {};

    const {
        state,
        feed,
        changeDiaper,
        throwCheese,
        cuddle,
        giveToy,
        giveSuce,
        putMusic,
        random: randomState,
        reset: resetState,
        // start,
        points,
        addPoints,
        // removePoints,
        makeLaugh,
        setPoints,
    } = useGotchiState({
        paused: (currentStep !== 'play' && currentStep !== 'start') || !current,
    });

    // Gotta wait for initial load for setup
    useEffect(() => {
        if (!isLoading && savedKid !== null) {
            setKid(savedKid);
            setCurrentStep('continue');
            if (savedPoints !== null) {
                setPoints(savedPoints || 0);
            }
        }
    }, [isLoading]);

    const onClickButton = useCallback(
        (e, button) => {
            audio.play('click_bouton');
            const { id } = button;
            switch (id) {
                case 'switch_head':
                case 'switch_color':
                case 'switch_look': {
                    const { key, choices } = button;
                    const { [key]: currentValue = choices[0] } = kid || {};
                    const currentIndex = choices.indexOf(currentValue);
                    const nextIndex = currentIndex === choices.length - 1 ? 0 : currentIndex + 1;
                    setKid({
                        ...kid,
                        [key]: choices[nextIndex],
                    });
                    break;
                }
                case 'milk': {
                    feed();
                    break;
                }
                case 'diaper': {
                    changeDiaper();
                    break;
                }
                case 'cheese': {
                    throwCheese();
                    break;
                }
                case 'cuddle': {
                    cuddle();
                    break;
                }
                case 'toy': {
                    giveToy();
                    break;
                }
                case 'suce': {
                    giveSuce();
                    break;
                }
                case 'music': {
                    putMusic();
                    break;
                }
                default:
                    break;
            }
        },
        [kid, setKid, feed, changeDiaper, throwCheese, cuddle, giveToy, giveSuce, putMusic, audio],
    );

    const containerRef = useRef();
    const deviceRef = useRef();

    const onClickCreate = useCallback(() => {
        setCurrentStep('create');
        resetState();
        audio.play('click_bouton');
        music.play('theme');
        makeLaugh();
    }, [setCurrentStep, resetState, audio, music]);

    const onClickNextName = useCallback(() => {
        setCurrentStep('name');
        audio.play('click_bouton');
    }, [setCurrentStep, audio]);

    const onClickNextSave = useCallback(() => {
        setCurrentStep('save');
        audio.play('click_bouton');
        makeLaugh();
    }, [setCurrentStep, audio]);

    const onClickPlay = useCallback(() => {
        submit({
            type: 'mollogotchi',
            document: documentId,
            [loginType === 'tel' ? 'phone' : loginType]: loginValue,
            kid,
            points: 0,
        });
        setCurrentStep('play');
        audio.play('click_save');
        audio.play('good');
        makeLaugh();
    }, [setCurrentStep, audio, loginType, loginValue, kid, documentId]);

    const onClickContinue = useCallback(() => {
        setCurrentStep('play');
        audio.play('click_save');
        audio.play('good');
        music.play('theme');
        makeLaugh();
    }, [setCurrentStep, audio, music]);

    const onSaveGame = useCallback(() => {
        if (kid !== null) {
            submit({
                type: 'mollogotchi',
                document: documentId,
                [loginType === 'tel' ? 'phone' : loginType]: loginValue,
                kid,
                points: points || 0,
            });
        }
    }, [loginType, loginValue, kid, documentId, points]);

    // Auto-save 15 secs
    const [saving, setSaving] = useState(false);
    useEffect(() => {
        const id = setInterval(() => {
            setSaving(true);
        }, 15000);
        return () => clearInterval(id);
    }, [setSaving]);

    useEffect(() => {
        if (currentStep === 'play' && saving) {
            onSaveGame();
            setSaving(false);
        }
    }, [currentStep, saving, onSaveGame, setSaving]);

    const [pixi, setPixi] = useState(null);
    const onKidMount = useCallback((pixiApp) => {
        setPixi(pixiApp);
    }, []);
    const onKidUnmount = useCallback(() => {
        setPixi(null);
    }, []);

    const onClickCamera = useCallback(() => {
        pixi.stop();
        pixi.renderer.extract.base64(pixi.stage).then((src) => {
            const link = document.createElement('a');
            link.href = src;
            link.download = 'Mollogotchi.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            pixi.start();
        });
    }, [pixi]);

    const onNameChange = useCallback(
        (newName) => {
            setKid({
                ...kid,
                name: newName,
            });
        },
        [setKid, kid],
    );

    const bottomTransitions = useTransition([currentStep], {
        initial: { opacity: 1 },
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        keys: (it) => it,
    });

    const messagesTransitions = useTransition([currentStep], {
        initial: { y: '0%' },
        from: { y: '-100%' },
        enter: { y: '0%' },
        leave: { y: '-100%' },
        keys: (it) => it,
    });

    useEffect(() => {
        if (currentStep !== 'start') {
            return () => {};
        }
        const interval = setInterval(() => {
            setKid({
                head: headShapes[Math.floor(Math.random() * headShapes.length)],
                skinColor: skinColors[Math.floor(Math.random() * skinColors.length)],
                look: looks[Math.floor(Math.random() * looks.length)],
                sleeping: Math.random() < 0.1,
            });
            randomState();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [currentStep, randomState]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.isLoading]: isLoading,
                    [styles.isPlaying]: currentStep === 'play',
                    [className]: className !== null,
                },
            ])}
            ref={containerRef}
        >
            <animated.div className={styles.deviceContainer} ref={deviceRef}>
                <animated.div
                    className={styles.device}
                    // style={{
                    //     height: to(
                    //         [containerHeight, deviceHeight, createSpring],
                    //         (maxHeight, height, createProgress) =>
                    //             createProgress > 0
                    //                 ? height + (maxHeight - height) * createProgress
                    //                 : null,
                    //     ),
                    // }}
                >
                    <img src={logo} alt="Mollo" className={styles.logo} />
                    <div className={styles.screen} ref={screenRef}>
                        <div className={styles.shape}>
                            <KidState style={state}>
                                <Kid
                                    width={Math.round(screenWidth)}
                                    height={Math.round(screenHeight)}
                                    audio={audio}
                                    className={styles.kid}
                                    onMount={onKidMount}
                                    onUnmount={onKidUnmount}
                                    {...kid}
                                />
                            </KidState>
                            <KidAudio
                                audio={audio}
                                paused={currentStep !== 'play' || !current}
                                style={state}
                            />
                            <KidPoints
                                style={state}
                                paused={currentStep !== 'play' || !current}
                                addPoints={addPoints}
                                // removePoints={removePoints}
                            />
                            <Gauge style={state} className={styles.gauge} />
                            {messagesTransitions((style, step) => (
                                <animated.div
                                    style={style}
                                    className={classNames([styles.message])}
                                >
                                    {step === 'create' ? (
                                        <>
                                            <strong className={styles.number}>1</strong>
                                            <span>Personnalise ton Mollogotchi</span>
                                        </>
                                    ) : null}
                                    {step === 'name' ? (
                                        <>
                                            <strong className={styles.number}>2</strong>
                                            <span>Donne-lui un nom</span>
                                        </>
                                    ) : null}
                                    {step === 'save' ? (
                                        <>
                                            <strong className={styles.number}>3</strong>
                                            <span>Enregistre ton enfant</span>
                                        </>
                                    ) : null}
                                </animated.div>
                            ))}
                            {name !== null ? <div className={styles.name}>{name}</div> : null}
                            <CircleButton className={styles.camera} onClick={onClickCamera}>
                                <img src={icons.photo} alt="Photo" className={styles.icon} />
                            </CircleButton>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        {bottomTransitions((style, step) => (
                            <animated.div
                                style={style}
                                className={classNames([
                                    styles.panel,
                                    {
                                        [styles.leave]: step !== currentStep,
                                    },
                                ])}
                            >
                                {step === 'start' ? (
                                    <Button
                                        className={classNames([styles.button, styles.big])}
                                        onClick={onClickCreate}
                                    >
                                        Créer mon Mollogotchi
                                    </Button>
                                ) : null}
                                {step === 'create' ? (
                                    <>
                                        <Toolbar
                                            className={styles.toolbar}
                                            buttons={createButtons}
                                            onClick={onClickButton}
                                            style={state}
                                        />
                                        <Button className={styles.button} onClick={onClickNextName}>
                                            Suivant
                                        </Button>
                                    </>
                                ) : null}
                                {step === 'name' ? (
                                    <>
                                        <TextField
                                            className={styles.field}
                                            placeholder="Nom de votre enfant"
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                        <Button className={styles.button} onClick={onClickNextSave}>
                                            Suivant
                                        </Button>
                                    </>
                                ) : null}
                                {step === 'save' ? (
                                    <>
                                        {user !== null ? (
                                            <UserField className={styles.field} />
                                        ) : (
                                            <ContactField
                                                placeholder="Entre ton n. de téléphone ou courriel"
                                                value={loginValue}
                                                onChange={onLoginValueChange}
                                                onTypeChange={onLoginTypeChange}
                                                className={styles.field}
                                            />
                                        )}
                                        <Button
                                            className={classNames([
                                                styles.button,
                                                styles.big,
                                                styles.last,
                                            ])}
                                            onClick={onClickPlay}
                                        >
                                            Jouer
                                        </Button>
                                    </>
                                ) : null}
                                {step === 'continue' ? (
                                    <Button
                                        className={classNames([styles.button, styles.big])}
                                        onClick={onClickContinue}
                                    >
                                        Continuer à jouer
                                    </Button>
                                ) : null}
                                {step === 'play' ? (
                                    <Toolbar
                                        className={styles.toolbar}
                                        playing
                                        buttons={buttons}
                                        onClick={onClickButton}
                                        style={state}
                                    />
                                ) : null}
                            </animated.div>
                        ))}
                    </div>
                </animated.div>
            </animated.div>
        </div>
    );
}

MolloGotchi.propTypes = propTypes;
MolloGotchi.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default MolloGotchi;
